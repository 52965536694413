import { ApiMock } from './api-mock';

export const GET_FIREBASE_TOKEN_AUTH: ApiMock = {
  url: /\/authentication\/get_firebase_token/,
  method: 'POST',
  response: {
    status: 200,
    body: {
      valid: true,
      // tslint:disable-next-line:max-line-length
      token: 'eyJ0eXAiOiAiSldUIiwgImFsZyI6ICJSUzI1NiIsICJraWQiOiAiNzBmNTU5M2NjODFiNWQ4OWJmZDg3NTllOWI1ZTg0NDM5YWYyMmUzYiJ9.eyJzdWIiOiAiZmlyZWJhc2UtYWRtaW5zZGstMmZ6OW1AcGxhbi12aXRhbC1kZXYtcWEuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCAiaWF0IjogMTU3OTExOTMxMywgImlzcyI6ICJmaXJlYmFzZS1hZG1pbnNkay0yZno5bUBwbGFuLXZpdGFsLWRldi1xYS5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsICJjbGFpbXMiOiB7ImlwIjogIjEwLjgxLjIzNC4zMCIsICJydXQiOiAiNTc4ODMwNzMiLCAidG9rZW4iOiAiYTY2YzM1NjFkZDg4YTRiMGQ4YmE0NTIxMDE1NDM1ZWUifSwgImV4cCI6IDE1NzkxMjI5MTMsICJ1aWQiOiAiNTc4ODMwNzMiLCAiYXVkIjogImh0dHBzOi8vaWRlbnRpdHl0b29sa2l0Lmdvb2dsZWFwaXMuY29tL2dvb2dsZS5pZGVudGl0eS5pZGVudGl0eXRvb2xraXQudjEuSWRlbnRpdHlUb29sa2l0In0.WVlnFhi0v6aj2CVvIWDbOcNoi8CkQhqveLdjfqCF2dl1qcBwHrpjGLUTY1Pj6_ect2329uAklrWFKNhBFcSOMC_obK9DxSl5auf3lfBhWlmsXCSovSoEQBuEF1VhmmKuxYagmSl0_zU7wqnWX5XiWdPJJYTItj8Z11mvpMUcsDyOS8_px3CIcPG2yaYlQrOj96f-w6oxQIwzEgy-iKq6G97CwbqjXgTfy1nBGBQv8sDfb8TfHug1rOBwdZCXOOI5dQxi4IG5t_agb1Eme-47_mfYgE8jVxt8EUrl0J-jBPlGGy3eVtBK9Q7MmyAzeBig2xIKRduCDAbiLkle4Cx9ww'
    }
  }
};
