import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import * as ALL_REQUESTS from '@app/mockups/api-mock';
import { ApiMock } from '@app/mockups/api-mock';

@Injectable()
export class MockInterceptor implements HttpInterceptor {
  LIST: any[] = null;
  constructor() {
    this.LIST = Object.keys(ALL_REQUESTS).map(key => ALL_REQUESTS[key]);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
      const currentUrl = request.url.split('?')[0];
      const currentMethod = request.method;
      let response = {};
      this.LIST.some((apiMock: ApiMock) => {
        if (apiMock.url && apiMock.url.test(currentUrl) && currentMethod === apiMock.method) {
          response = apiMock.response.body;
          return true;
        }
      });
      return of(new HttpResponse({ status: 200, body: response }));
  }
}
