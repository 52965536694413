import { Component } from '@angular/core';
import { PLAN_VITAL_SITE, PLANVITAL_PHONE } from '@constants/constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public siteUrl = PLAN_VITAL_SITE;
  public phone = PLANVITAL_PHONE;

  constructor() { }

}
