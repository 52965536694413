import { ApiMock } from './api-mock';

export const VALIDATE_USER: ApiMock = {
  url: /\/clients\//,
  method: 'POST',
  response: {
    status: 401,
    body: {
      authMethod: 1
    }
  }
};
