import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetFirebaseTokenAuthResponse } from '@interfaces/get-firebase-token-auth';
import { USER_CLAIMS_MOCK } from '@mockups/claims';

@Injectable({
  providedIn: 'root'
})
export class AuthManagerService {
  sessionToken = '';
  credential: firebase.auth.UserCredential = null;

  constructor(
    private firebaseAuth: AngularFireAuth,
    private http: HttpClient
  ) { }

  public async getSessionToken(token: string): Promise<void> {
    this.sessionToken = '';
    if (environment.name === 'local') {
      this.sessionToken = '1234asdf';
      return Promise.resolve();
    }
    this.credential = await this.firebaseAuth.auth.signInWithCustomToken(token);
    if (this.credential) {
      this.sessionToken = await this.credential.user.getIdToken();
    } else {
      return Promise.reject();
    }
    return this.sessionToken ? Promise.resolve() : Promise.reject();
  }

  public validateToken(token: string): Observable<GetFirebaseTokenAuthResponse> {
    return this.http.post<GetFirebaseTokenAuthResponse>(
      `${environment.ORCHESTRATOR_URL}/auth/authentication/get_firebase_token/`, { cipher: token });
  }

  public async getClaims() {
    return environment.name === 'local' ? Promise.resolve(
      USER_CLAIMS_MOCK
    ) :
      await this.credential.user.getIdTokenResult()
        .then((result) => result.claims)
        .catch(() => null);
  }
}
