import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { GetSecurityPasswordStateResponse } from '@interfaces/get-security-password-state';

@Injectable({
  providedIn: 'root'
})
export class OrchestratorService {

  constructor(
    private http: HttpClient,
  ) { }

  public getSecurityPasswordState(userId: string): Observable<GetSecurityPasswordStateResponse> {
    return this.http.get<GetSecurityPasswordStateResponse>(`${environment.ORCHESTRATOR_URL}/security/key/status/?rut=${userId}`);
  }

  public getTokenWithIp(userId: string): Observable<any> {
    return this.http.post<any>(`${environment.ORCHESTRATOR_URL}/transfer/post-venta/get-token/`, { rut: userId, origin: 'WSP' });
  }
}
