export const environment = {
  production: false,
  name: 'qa',
  SERVICES_TIMEOUT: 30000,
  BACKEND_URL: 'http://104.196.159.111/sacu-password',
  ORCHESTRATOR_URL: 'http://104.196.159.111',
  firebaseConfig: {
    apiKey: 'AIzaSyAGbRArJYj704Prbq8iEtcAx88qBQFH_wk',
    authDomain: 'plan-vital-dev-qa.firebaseapp.com',
    databaseURL: 'https://plan-vital-dev-qa.firebaseio.com',
    projectId: 'plan-vital-dev-qa',
    storageBucket: 'plan-vital-dev-qa.appspot.com',
    messagingSenderId: '381542100011',
  },
};
