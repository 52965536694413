import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from '@components/footer/footer.component';
import { HeaderComponent } from '@components/header/header.component';
import { LoadingComponent } from '@components/loading/loading.component';
import { DEFAULT_TIMEOUT, Interceptor } from '@services/interceptor.service';
import { AuthManagerService } from '@services/auth-manager.service';
import { environment } from '@environments/environment';
import { MockInterceptor } from '@services/mock-interceptor';
import { OrchestratorService } from '@services/orchestrator.service';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { MatDialogModule } from '@angular/material';
import { LoadingService } from '@providers/loading.service';


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    LoadingComponent,
    GenericModalComponent,
  ],
  entryComponents: [
    GenericModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    MatDialogModule,
  ],
  providers: [
    { provide: DEFAULT_TIMEOUT, useValue: environment.SERVICES_TIMEOUT },
    { provide: HTTP_INTERCEPTORS, useClass: environment.name !== 'local' ?  Interceptor : MockInterceptor, multi: true },
    AuthManagerService,
    OrchestratorService,
    LoadingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
