export const PLAN_VITAL_SITE = 'https://www.planvital.cl';
export const PLANVITAL_PHONE = '600 5000 800';

export const CONTRACT_VALID_DAYS = 10;

export const HTTP_SERVER_ERROR = 500;
export const BAD_REQUEST_ERROR = 400;
export const UNAUTHORIZED_ERROR = 401;

// tslint:disable-next-line: max-line-length
export const EMAIL_PATTERN =  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_PATTERN = /^[1-9][0-9]*$/;
export const SERIAL_ID_PATTERN = /([0-9]{8,9}|A[0-9]{9})/;
export const PIN_PATTERN = /([0-9]{4})/;

export enum AuthenticationMethod {
  TFA_ANY_PIN = 1,
  TFA_EMAIL_PIN = 2,
  TFA_CELLPHONE_PIN = 3,
  SECURITY_QUESTIONS = 4
}
