export const USER_CLAIMS_MOCK = {
  name: '{ "firstName": "Julia Rosario", "lastName": "Paredes", "motherLastName": "Rios" }',
  ip: 'app.mobile',
  plan_vital_token: 'a6c3d9cca1fbd44d52eb70eee49e28a3',
  iss: 'https://securetoken.google.com/plan-vital-dev-qa',
  aud: 'plan-vital-dev-qa',
  auth_time: 1579288032,
  user_id: '22759084K',
  sub: '22759084K',
  iat: 1579288032,
  exp: 1579291632,
};
