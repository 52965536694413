import { ApiMock } from './api-mock';

export const GET_SECURITY_PASSWORD_STATE: ApiMock = {
  url: /\/security\/key\/status/,
  method: 'GET',
  response: {
    status: 200,
    body: {
      result: true,
      code: 90003
    }
  }
};
