import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public isLoading: EventEmitter<boolean>;

  constructor() {
    this.isLoading = new EventEmitter();
  }

  public showLoading() {
    this.isLoading.emit(true);
  }

  public hideLoading() {
    this.isLoading.emit(false);
  }
}
