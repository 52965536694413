import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from '@interfaces/dialog-data';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent {

  constructor(
    public dialogRef: MatDialogRef<GenericModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  public dialogClose() {
    this.dialogRef.close();
  }

  public primaryAction() {
    this.dialogRef.close('primaryButtonPressed');
  }

  public secondaryAction() {
    this.dialogRef.close('secondaryButtonPressed');
  }

}
