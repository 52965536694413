import { Component } from '@angular/core';
import { LoadingService } from '@providers/loading.service';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { SESSION_ERROR_MESSAGE, SESSION_ERROR_TITLE } from '@constants/error-messages';
import { PLAN_VITAL_SITE } from '@constants/constants';
import { MatDialog } from '@angular/material';
import { CommunicationProvider } from '@providers/communication';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public showLoading = true;
  constructor(
    private loadingService: LoadingService,
    private communicationProvider: CommunicationProvider,
    private dialog: MatDialog
  ) {
    this.loadingService.isLoading.subscribe((isLoading: boolean) => setTimeout(() => this.showLoading = isLoading, 0));
    this.communicationProvider.requestIntercepted.subscribe(() => this.showSessionError());
  }

  public showSessionError() {
    setTimeout(() => this.loadingService.hideLoading(), 0);

    const dialogRef = this.dialog.open(GenericModalComponent, {
      panelClass: 'alert-modal',
      data: {
        title: SESSION_ERROR_TITLE,
        message: SESSION_ERROR_MESSAGE,
        primaryButtonText: 'Volver a PlanVital',
        icon: 'i-time',
      }
    });
    dialogRef.afterClosed().subscribe(
      () => {
        window.open(PLAN_VITAL_SITE, '_self');
      });
  }

}
