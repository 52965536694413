// tslint:disable:max-line-length
import { PLANVITAL_PHONE as phone } from '@constants/constants';

export const SECURITY_PASSWORD_OK_CODE = 90003;
export const SECURITY_PASSWORD_ACTIVATED_TITLE = 'Ya tienes tu Clave de Seguridad';
export const SECURITY_PASSWORD_ACTIVATED_MESSAGE = 'Tu clave de seguridad ya se encuentra activa. Si la olvidaste, puedes recuperarla usando la funcionalidad <strong>Recuperación de clave de seguridad en el sitio de PlanVital.</strong>';

export const SESSION_ERROR_TITLE = 'No hemos podido validar tu sesión.';
export const SESSION_ERROR_TITLE_EXPIRED = 'Tu sesión ha expirado.';
export const SESSION_ERROR_MESSAGE = 'Vuelve a ingresar al sitio de PlanVital a Claves > Solicitar Clave de Seguridad.';

export const GENERIC_ERROR_TITLE = '¡Oh no! Ha ocurrido un error.';
export const GENERIC_ERROR_MESSAGE = 'Hubo un problema al cargar la información. Por favor intenta de nuevo.';

export const NO_CONTACT_INFO_MATCH_TITLE = '¡Tus datos no coinciden!';
export const NO_CONTACT_INFO_MATCH_MESSAGE = 'Si no tienes correo electrónico, número de celular o cambiaste tus datos, solicita tu Clave de Seguridad a través de nuestro contact center <a class="btn-phone" href="tel:' + phone + '">FonoVital <span>' + phone + '</span></a> o en cualquiera de nuestras sucursales a lo largo del país.';

export const CONTRACT_GENERATION_ERROR_TITLE = '¡Oh no, no hemos podido generar tu contrato!';
export const CONTRACT_GENERATION_ERROR_MESSAGE = 'Ya tienes una solicitud de Clave de Seguridad. Por favor comunícate con nosotros a través de nuestro contact center <a class="btn-phone" href="tel:' + phone + '">FonoVital <span>' + phone + '</span></a> o en cualquiera de nuestras sucursales a lo largo del país.';
