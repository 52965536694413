import { Injectable, Inject, InjectionToken } from '@angular/core';
import { timeout, catchError } from 'rxjs/operators';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { ErrorResponse } from '@interfaces/error-response.model';
import { AuthManagerService } from './auth-manager.service';
import { HTTP_SERVER_ERROR, UNAUTHORIZED_ERROR } from '@constants/constants';
import { CommunicationProvider } from '@providers/communication';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable({
  providedIn: 'root'
})
export class Interceptor implements HttpInterceptor {

  constructor(
    private authManagerService: AuthManagerService,
    private communicationProvider: CommunicationProvider,
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = request.headers;
    if (!headers.has('Content-Type')) {
      headers = headers.append('Content-Type', 'application/json');
    }
    if (!headers.has('Accept')) {
      headers = headers.append('Accept', 'application/json');
    }
    if (this.authManagerService.sessionToken) {
      headers = headers.append('Authorization', this.authManagerService.sessionToken);
    }

    return from(this.handleRequest(headers, request, next));

  }

  private async handleRequest(headers: HttpHeaders, request: HttpRequest<any>, next: HttpHandler) {

    const timeoutValue = Number(request.headers.get('timeout')) || this.defaultTimeout;
    const updatedRequest = request.clone({ headers });

    return next.handle(updatedRequest)
      .pipe(
        timeout(timeoutValue),
        catchError((error: HttpErrorResponse) => {
          if (error.status === UNAUTHORIZED_ERROR) {
            this.communicationProvider.getInterceptedSource().next(error.status);
          }
          return throwError(this.handleError(error));
        })
      ).toPromise();
  }

  private handleError(error: HttpErrorResponse): ErrorResponse {
    const errorResponse = new ErrorResponse();
    errorResponse.statusCode = error.status;
    if (error.error) {
      if (error.error.code) {
        errorResponse.code = error.error.code;
      }
      if (error.error.message) {
        errorResponse.message = (error.status === HTTP_SERVER_ERROR) ? 'En estos momentos no podemos atender tu solicitud' :
          error.error.message;
      }
      if (error.error.messageDescription) {
        errorResponse.messageDescription = error.error.messageDescription;
      }
      if (error.error.errorMessage) {
        errorResponse.errorMessage = error.error.errorMessage;
      }
    }
    return errorResponse;
  }

}
